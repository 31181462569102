import React from "react";
import Layout from "../../../../other/layout";
import EasyOptimizer from "../../../../components/AC-StaticPages/Developpez/ServicesWeb/PromotionDeSiteWeb/EasyOptimizer";
import SEO from "../../../../other/seo";

export default function EasyOptimizerPage() {
  return (
    <Layout>
      <SEO title="EasySiteOptimizer!" />
      <EasyOptimizer />
    </Layout>
  );
}
