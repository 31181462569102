import React from "react";
import styles from "./styles.module.css";
import Button from "../../../components/Button";
import getFound from "../../../../../assets/img/getfound.gif";
import comparer from "../../../../../assets/img/getfound.gif";

export default function EasyOptimizer() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <h1>EasySiteOptimizer!</h1>
        <img
          src={getFound}
          alt="Graphique de tendance à la hausse"
          style={{ float: "right" }}
        ></img>
        <p>
          Les moteurs de recherche sont la porte d'entrée de l'Internet. Ils
          sont d'ordinaire le premier endroit où les usagers du Web vont
          lorsqu'ils cherchent de l'information. Comme 67 % des utilisateurs des
          moteurs de recherche ne consultent que les 3 premières pages des
          résultats, il est essentiel que votre entreprise obtienne le plus haut
          classement possible!
        </p>
      </div>
      <div className={styles.row}>
        <p>
          <span className="bold">EasySiteOptimizer</span> est conçu pour vous
          aider à optimiser votre site pour le référencement et maximiser vos
          chances d'obtenir de plus hauts classements. Notre application
          intelligente utilise un navigateur pour vous guider au fil d'une série
          d'étapes au cours desquelles vous déterminerez les mots clés à
          associer à votre entreprise, analyserez le contenu et la structure de
          votre site Web, puis soumettrez celui-ci aux moteurs de recherche les
          plus populaires.
        </p>
      </div>
      <div className={styles.row}>
        <div className={styles.marginTop}>
          <span className="bold">Pourquoi choisir EasySiteOptimizer?</span>

          <div className={styles.list}>
            <ul className={styles.bullet}>
              <li>Augmente l'achalandage de votre site</li>
              <li>
                Maximise vos possibilités de bon classement dans les résultats
                de recherche
              </li>
              <li>Génère des indications de clients ciblées</li>
              <li>
                Effectue un suivi des classements du site pour permettre une
                amélioration constante{" "}
              </li>
            </ul>
            <Button
              text={"comparer les forfaits"}
              url={
                "/developpez/services-web/solutions-pour-le-cybercommerce/forfaits-cybercommerce/"
              }
              margin={"10px 0"}
              minWidth={"200px"}
            />
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <table
          width="100%"
          border="0"
          cellspacing="5"
          cellpadding="5"
          className={[styles.table, styles.twoColumnTable].join(" ")}
        >
          <thead>
            <tr>
              <th>
                <h2>Applications</h2>
              </th>
              <th>
                <h2>Avantages</h2>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="bold">Générateur de mots-clés :</span>
                <p>
                  {" "}
                  Le générateur vous invitera à entrer des adjectifs décrivant
                  votre entreprise, ce qui permettra au programme de balayer les
                  moteurs de recherche pour trouver des mots similaires obtenant
                  en ce moment de plus hauts classements. L’option vous est
                  alors offerte d’ajouter automatiquement les mots-clés
                  sélectionnés à votre page d’index.
                </p>
              </td>
              <td>
                <ul>
                  <li>
                    Identifie facilement les mots et phrases courants associés à
                    un type d’entreprise
                  </li>
                  <li>
                    Suggère des mots qui génèrent de plus hautes positions aux
                    classements
                  </li>
                  <li>
                    Vous permet d’ajouter automatiquement les mots choisis à la
                    page d’index de votre site.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Analyseur de contenu :</strong>
                <p>
                  L’analyseur balaie chacune des pages du site afin de vous
                  proposer des suggestions de balises Méta, de disposition et de
                  contenu pertinents pour le référencement dans les moteurs de
                  recherche. Vous pouvez éditer le code HTML de votre site
                  directement dans l’application EasySiteOptimizer en fonction
                  des recommandations que le programme vous donne.
                </p>
              </td>
              <td>
                <ul className={styles.bullet}>
                  <li>
                    Produit un bulletin de rendement exposant les points faibles
                    de l’optimisation de votre site et vous suggère des moyens
                    d’améliorer chaque page individuellement
                  </li>
                  <li>
                    Permet une visualisation simple des suggestions et l’édition
                    directe (à même l’application) du HTML de votre site
                  </li>
                  <li>
                    Affiche une analyse historique/longitudinale du site,
                    incluant le nombre de pages et des suggestions
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <span className="bold">Référenceur de site :</span>
                <p>
                  L’outil de référencement vous donne la possibilité de
                  soumettre automatiquement votre site aux moteurs de recherche
                  de votre choix. Parmi les moteurs intégrés au référenceur, on
                  compte Google, Yahoo!, Live Search, et Lycos.
                </p>
              </td>
              <td>
                <ul className={styles.bullet}>
                  <li>Signale l’existence d’un site sur internet</li>
                  <li>
                    Incite les « robots de recherche » (searchbots) à
                    reconnaître et recenser rapidement un site
                  </li>
                  <li>Permet de soumettre le site à plusieurs moteurs</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
